import LotteryResultColumn from '@/components/lottery/lottery-result/lottery-result-column/LotteryResultColumn.vue';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
        LotteryResultColumn
    },
    setup() {
        const resultCount = 100;
        return { resultCount };
    }
});
