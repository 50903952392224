import { defineComponent, onMounted, ref } from 'vue';
import LotteryHeading from '@/components/lottery/lottery-heading/LotteryHeading.vue';
import LotteryResultHeader from '@/components/lottery/lottery-result/lottery-result-header/LotteryResultHeader.vue';
import LotteryResult from '@/components/lottery/lottery-result/LotteryResult.vue';
import Subpage from '@/components/layout/subpage/Subpage.vue';
import { getHistoryAward, getLastAward } from '@/assets/ts/api/lottery';
import { LotteryIconClassEnum } from '@/assets/ts/enums/lottery-icon-class.enum';
import { LotteryRowClassEnum } from '@/assets/ts/enums/lottery-row-class.enum';
import { LotteryAwardKeyEnum, LotteryAwardLabelEnum } from '@/assets/ts/enums/lottery-award.enum';
export default defineComponent({
    components: {
        LotteryHeading,
        LotteryResultHeader,
        LotteryResult,
        Subpage
    },
    setup() {
        const title = ref('');
        const currentLotteryId = ref(0);
        const isAvailablePrevHistory = ref(false);
        const isAvailableNextHistory = ref(false);
        onMounted(() => {
            getResult();
        });
        const saveCurrentResultData = (results) => {
            currentLotteryId.value = results.result_id;
            isAvailablePrevHistory.value = results.prev;
            isAvailableNextHistory.value = results.next;
            title.value = `Wyniki ${results.month} ${results.year}`;
        };
        const showHistory = (delta) => {
            getHistoryAward(currentLotteryId.value + delta).then((results) => {
                saveCurrentResultData(results);
                drawResult(JSON.parse(results.rewards));
                drawNicknames(results.nicknames);
            }).catch((err) => {
                console.log(err);
            });
        };
        function getResult() {
            getLastAward().then((results) => {
                saveCurrentResultData(results);
                drawResult(JSON.parse(results.rewards));
                drawNicknames(results.nicknames);
            }).catch((err) => {
                console.log(err);
            });
        }
        const clearAwardClasses = (rowHtml, iconHtml) => {
            const rowClasses = [
                LotteryRowClassEnum.Money,
                LotteryRowClassEnum.Shard,
                LotteryRowClassEnum.Ticket,
                LotteryRowClassEnum.Currency,
            ];
            const iconClasses = [
                LotteryIconClassEnum.Money,
                LotteryIconClassEnum.Shard,
                LotteryIconClassEnum.Ticket,
                LotteryIconClassEnum.Currency
            ];
            rowClasses.forEach((rowClass) => {
                if (rowHtml.classList.contains(rowClass)) {
                    rowHtml.classList.remove(rowClass);
                }
            });
            iconClasses.forEach((iconClass) => {
                if (iconHtml.classList.contains(iconClass)) {
                    iconHtml.classList.remove(iconClass);
                }
            });
        };
        function drawResult(nicknames) {
            Object.entries(nicknames).map(rewards => {
                let index = rewards[0];
                let rewardVal = rewards[1];
                if (index !== 'month' && index !== 'year') {
                    let rewardIconClass;
                    let reward = rewards[1];
                    let resultDiv = document.getElementById('result' + index);
                    if (resultDiv !== null) {
                        const rewardIconHtml = resultDiv.querySelector('.reward-icon');
                        clearAwardClasses(resultDiv, rewardIconHtml);
                        if (rewardVal === LotteryAwardKeyEnum.Shard) {
                            resultDiv.classList.add(LotteryRowClassEnum.Shard);
                            reward = LotteryAwardLabelEnum.Shard;
                            rewardIconClass = LotteryIconClassEnum.Shard;
                        }
                        else if (rewardVal === LotteryAwardKeyEnum.Ticket || rewardVal === LotteryAwardKeyEnum.TicketX2) {
                            resultDiv.classList.add(LotteryRowClassEnum.Ticket);
                            reward = rewardVal === LotteryAwardKeyEnum.Ticket ? LotteryAwardLabelEnum.Ticket : LotteryAwardLabelEnum.TicketX2;
                            rewardIconClass = LotteryIconClassEnum.Ticket;
                        }
                        else if (rewardVal.indexOf('kw.') > -1) {
                            resultDiv.classList.add(LotteryRowClassEnum.Currency);
                            rewardIconClass = LotteryIconClassEnum.Currency;
                        }
                        else if (reward.indexOf('m') > -1) {
                            resultDiv.classList.add(LotteryRowClassEnum.Money);
                            rewardIconClass = LotteryIconClassEnum.Money;
                        }
                        else {
                            rewardIconClass = 'empty';
                            reward = '';
                        }
                        rewardIconHtml.classList.add(rewardIconClass);
                        resultDiv.querySelector('.reward').innerHTML = reward;
                    }
                }
            });
        }
        function drawNicknames(nicknames) {
            const results = document.querySelectorAll('.result-column');
            results.forEach((resultEl) => {
                resultEl.querySelector('.badge').innerHTML = '';
            });
            if (nicknames) {
                for (const [index, nick] of Object.entries(nicknames)) {
                    const resultDiv = document.getElementById(`result${index}`);
                    resultDiv === null || resultDiv === void 0 ? void 0 : resultDiv.querySelector('.badge').append(nick);
                }
            }
        }
        return {
            showHistory,
            isAvailablePrevHistory,
            isAvailableNextHistory,
            title
        };
    }
});
