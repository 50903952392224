import { defineComponent, onMounted, ref } from 'vue';
import { getTimer } from '@/assets/ts/api/lottery';
import { countDownHelper } from '@/assets/ts/helpers/time';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
export default defineComponent({
    components: {
        CTAButton
    },
    props: {
        availablePrevHistory: {
            type: Boolean,
            default: false,
            required: false,
        },
        availableNextHistory: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
    emits: ['show-history'],
    setup(props, context) {
        const timer = ref(null);
        const timeString = ref({
            days: '0',
            hours: '00',
            minutes: '00',
            seconds: '00'
        });
        onMounted(() => initTimer());
        function initTimer() {
            getTimer().then((results) => {
                startCounting(results.currentDatetime, results.destinationDatetime);
            });
        }
        const showHistoryResult = (delta) => {
            context.emit('show-history', delta);
        };
        function startCounting(currentTime, destinationTime) {
            const countDownDate = new Date(destinationTime.year, destinationTime.month, destinationTime.day, destinationTime.hour, destinationTime.minute, destinationTime.second).getTime();
            const currentTimeDate = new Date(currentTime.year, currentTime.month, currentTime.day, currentTime.hour, currentTime.minute, currentTime.second);
            countDown(countDownDate, currentTimeDate);
            timer.value = setInterval(() => {
                countDown(countDownDate, currentTimeDate);
            }, 1000);
        }
        function countDown(countDownDate, currentTimeDate) {
            const countDownString = countDownHelper(countDownDate, currentTimeDate);
            timeString.value = {
                days: countDownString.days.toString(),
                hours: countDownString.hours.toString(),
                minutes: countDownString.minutes.toString(),
                seconds: countDownString.seconds.toString()
            };
            currentTimeDate.setSeconds(currentTimeDate.getSeconds() + 1);
            if (countDownString.distance < 0) {
                clearInterval(timer.value);
            }
        }
        return {
            showHistoryResult,
            timeString
        };
    }
});
